.progess-safe{
    opacity: 1;
    width: 50%;
    background-color: green;
}

.text-progressbar{
    text-align: center;
    font-size: large;
    padding-top: 10vh;
}

.progressBar{
    width: 50%;
}

.percentage-progressbar{
    text-align: center;
    font-size: large;
}