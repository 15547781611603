scroll-container {
  display: block;
  height: 200px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.text-safe {
  color: green;
}

.text-not-safe {
  color: red;
}

.searchList {
  padding-top: 25px;
  padding-right: 50px;
  padding-bottom: 0px;
  padding-left: 50px;
  width: 75%;

}

.search-form {
  display: flex;
  align-items: center;
  justify-content: left;
}


.wrapper {
  margin: 0 auto;
  padding: 40px;
  max-width: 800px;
}

.table {
  margin: 0 0 40px 0;
  width: 99.2%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: block;
  border: 3px solid #3b70b1;
  border-radius: 10px;
}

.row {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  
}

.row:nth-last-of-type(odd) {
  background-color: #e9e9e9;
}

.row.header {
  font-weight: 1000;
  color: #ffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #3b70b1;
  width: 100%;
  margin-left: 0px;
  margin-bottom: 15px;
}

.searchContainer .cell {
  width: 25%;
  text-align: center;
}

.searchContainer .name {  
  display: table-cell;
  background-color: white;
  
}

.searchContainer .empresa {
  display: table-cell;
  background-color: white;  
}

.searchContainer .tipo {  
  display: table-cell;
  background-color: white;  
}

.searchContainer .mp {  
  display: table-cell;
  background-color: white;  
}

/* .cell-index-name {
  width: 25%;
  text-align: center;
}

.cell-index-empresa {
  width: 25%;
  text-align: center;
}

.cell-index-tipo {
  width: 25%;
  text-align: center;
}

.cell-index-mp {
  width: 25%;
  text-align: center;
} */

.reset-button {
  text-align: center;
  padding-top: 3%;
 
}

/* RESPONSIVENESS */

@media screen and (max-width: 1112px) {
  .searchList {
    width: 90%;
  }
}

@media screen and (max-width: 964px) {
  .searchContainer .cell {
    width: 30%;
    text-align: center;
  }
  .searchContainer .index-mp {  
    width: 10%; 
  }
  .searchContainer .mp {  
    width: 10%;
    display: table-cell;
    background-color: white;
  }
}


/* @media screen and (max-width: 500px) {
  .cell {
    padding: 2px 16px;
    display: block;
  }
} */

@media screen and (max-width: 655px) {
  .searchList {
    width: 98%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 500px) {
  .searchList .row {
    font-size: 0.8rem;
  }  
}

@media screen and (max-width: 415px) {
  .searchList .row {
    font-size: 0.6rem;
  }  
}



/* @media screen and (max-width: 580) {
  .row {
    padding: 14px 0 7px;
  }

  .row.header {
    padding: 0;
    height: 6px;
  }

  .row .cell {
    margin-bottom: 10px;
  }

  .row .cell::before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969699;
    display: block;
  }
}

@media screen and (max-width: 580) {
  .table {
    display: block;
  }
} */