html {
  height: 100%;
  width: 100%;
}
body {
  min-height: 100%;
  min-width: 100%;
}

.content-wrap {
  padding-bottom: 2.5rem;    /* Footer height */
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.content-wrap-wind {
  padding-bottom: 2.5rem;    /* Footer height */  
}