.active-pink-2 input.form-control[type=text]:focus:not([readonly]) {
    border-bottom: 1px solid #f48fb1;
    box-shadow: 0 1px 0 0 #f48fb1;
  }
  .active-pink input.form-control[type=text] {
    border-bottom: 1px solid #f48fb1;
    box-shadow: 0 1px 0 0 #f48fb1;
  }
  .active-purple-2 input.form-control[type=text]:focus:not([readonly]) {
    border-bottom: 1px solid #ce93d8;
    box-shadow: 0 1px 0 0 #ce93d8;
  }
  .active-purple input.form-control[type=text] {
    border-bottom: 1px solid #ce93d8;
    box-shadow: 0 1px 0 0 #ce93d8;
  }
  .active-cyan-2 input.form-control[type=text]:focus:not([readonly]) {
    border-bottom: 1px solid #4dd0e1;
    box-shadow: 0 1px 0 0 #4dd0e1;
  }
  .active-cyan input.form-control[type=text] {
    border-bottom: 1px solid #4dd0e1;
    box-shadow: 0 1px 0 0 #4dd0e1;
  }
  .active-cyan .fa, .active-cyan-2 .fa {
    color: #4dd0e1;
  }
  .active-purple .fa, .active-purple-2 .fa {
    color: #ce93d8;
  }
  .active-pink .fa, .active-pink-2 .fa {
    color: #f48fb1;
  }