.login-main {
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;    
    background: rgb(2,0,36);
    background: -moz-linear-gradient(115deg, rgba(2,0,36,1) 0%, rgba(9,75,155,1) 0%, rgba(0,212,255,1) 64%);
    background: -webkit-linear-gradient(115deg, rgba(2,0,36,1) 0%, rgba(9,75,155,1) 0%, rgba(0,212,255,1) 64%);
    background: linear-gradient(115deg, rgba(2,0,36,1) 0%, rgba(9,75,155,1) 0%, rgba(0,212,255,1) 64%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1);
}

.login-form {
    margin-left: 2rem;
}

.login-form .username-input {
    box-sizing: border-box;
    height: 100%;
    border: 0px;
    padding-left: 0.5rem;
    border-radius: 0px 0px 0px 0px; 
}

.login-form .user-icon {
    display: inline-block;   
    box-sizing: border-box; 
    background-color: white;
    height: 100%;
    padding: 0.5rem;  
    border-radius: 0px 0px 0x 0px;    
}

.login-form .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: inline-block;    */
    box-sizing: border-box; 
    height: 50px;
    background-color: white;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 5px 5px 5px 5px;     
}

.login-main .logo-section {
    height: 175.2px;
    border-left: 2px solid rgb(255, 255, 255);
    margin-left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-main .logo-section .text {
    color: white;
    font-family: monospace;
    font-weight: bolder;
    font-size: 3rem;
    padding: 2rem;
    text-align: center;
    
}

.login-main .button-container {
    display: flex;
    justify-content: end;
    
}

.login-main button {
    background-color: black;
    color: white;    
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;
    border-radius: 5px;
}

.login-main button:hover {
    background-color:maroon;
}


/* MEDIA QUERIES  */

@media only screen and (max-width: 582px) {
    .login-main {
        flex-direction: column;
    }
    .login-main .logo-section {
        border-left: none;        
    }
    .login-main .logo-section .text {
        padding-bottom: 0px;
        border-bottom: 2px solid rgb(255, 255, 255);
        text-align: center;
    }
}