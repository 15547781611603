.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #094b9b;
  height: 8vh;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 1;
  padding-left: 0px;  
}

.menu-bars {
  margin-left: 1.3rem;
  font-size: 1.3rem;
  background: none;
  align-items: center;
}

.dashboard-symbol {
  transform: scale(1.5);  
  text-decoration: none;
  color: white;
}

.dashboard-symbol a {
  text-decoration: none;
  color: white;
}

.dashboard-symbol:hover {
  text-decoration: none;
  color: white;
}

.nav-menu {
  background-color: #094b9b;
  height: 100vh;
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  padding-top: 3%;
  padding-bottom: 20%;
}


@media screen and (max-width: 930px) {
  .nav-menu {
    display: none;
  }
}

.nav-text {  
  color: white;
  display: block;  
  font-size: large;
  transition: all .2s ease-in-out;
  
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  margin-top: 5%;
  font-size: 18px; 
}


/* .nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: end;
} */

/* .nav-text:hover {  
  background-color: black;
} */

.nav-menu-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* transition: background-color 0.5s ease; */
}

.nav-item {
  display:flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 1em;
  height: 64px;
  transition: background-color 0.2s ease;
}

.nav-item:hover {  
  background-color: black;
}

.nav-item-last {
  display:flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 1em;
  height: 64px;
  transition: background-color 0.2s ease;
  margin-left: 35%;
  margin-right: 1%;
}

.menu-icon {
  margin-right: 10px;
  margin-left: 10px;
}

h1 {
  align-items: center;
  margin-left: 100px;
}

.page-content-sidebar-open {
  padding-left: 140px;
  transition: 350ms;
}

.page-content-sidebar-closed {
  transition: 850ms;
}

#graph {
  padding-left: 50px;
}

.horizontal-rule {
  border: 1px solid gainsboro;
  z-index: 10;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 66%;
}

.logged-user-text {
  color: white;
  font-size: 0.8rem;
  font-family: Arial, Helvetica, sans-serif;  
}

/* .meeting-point-title {
  margin-right: 4%;
} */

/* RESPONSIVENESS */

@media only screen and (max-width: 1378px) {
  .nav-item-last {
    margin-left: 27%;
  }

  @media only screen and (max-width: 1210px) {
    .nav-item-last {
      margin-left: 25%;
    }
  } 

  @media only screen and (max-width: 1193px) {
    .nav-item-last {
      margin-left: 20%;
    }
  }

  @media only screen and (max-width: 1102px) {
    .nav-item-last {
      margin-left: 15%;
    }
  }

  @media only screen and (max-width: 1036px) {
    .nav-item-last {
      margin-left: 10%;
    }
  }

  @media only screen and (max-width: 974px) {
    .nav-item-last {
      margin-left: 5%;
    }
  }

  @media only screen and (max-width: 922px) {
    .nav-item-last {
      margin-left: 25%;
    }
    .menu-text {
      display: none;
    }
  }

  @media only screen and (max-width: 843px) {
    .nav-item-last {
      margin-left: 17%;
    }
    .menu-text {
      display: none;
    }
  }

  @media only screen and (max-width: 760px) {
    .nav-item-last {
      margin-left: 13%;
    }
  }
 

  @media only screen and (max-width: 723px) {
    .nav-item-last {
      margin-left: 10%;
    }
  }

  @media only screen and (max-width: 701px) {
    .nav-item-last {
      margin-left: 0%;
    }
  }
}