.gvir-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content:center;
    align-items: center;  
}

.gvir-main .header-text {
    padding: 2% 0 2% 0;    
}

.table-row:hover {
    background-color: lightgray;
}