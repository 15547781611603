.home-open {
  padding-left: 2vw;
  padding-right: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* @media screen and (max-width: 930px) {
  .home-open {
    padding-left: 0vw;
  }
} */


.content-table {
  width: 30vw;
}

.box-config {
  display: flex;
  justify-content: space-around;
  margin-top: 4vh;
  margin-bottom: 4vh;   
}

.box-config > :first-child {
  margin-left: 3vh;
}

.box-config > :last-child {  
  margin-right: 3vh;
}


.box-details {
  display: flex;
  justify-content: space-around;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.searchContainer {
  display: flex;
  justify-content: center;
  background-color: white;
  margin-bottom: 1vh;
}

.progressBarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loading-alert {
  padding-top: 100px;
}

.cards {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.card-info {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  z-index: 99999;
}

.content-table {
  background-color: white;
  height: 32rem;
  width: 20rem;
  margin-right: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 5px 3px -2px gray, 0 2px 2px gray;
}

.reset {
  text-align: center;
  margin-bottom: 20vh;
}

.header-config {
  text-align: center;  
}

@media screen and (max-width: 1150px) {
  .box-config {
    margin-left: 5vw;
  }
}


@media screen and (max-width: 930px) {
  .box-config {
    margin-left: 20vw;
    flex-direction: column;
  }
}

@media screen and (max-width: 930px) {
  .box-details {
    margin-left: 20vw;
    flex-direction: column;
    margin-bottom: 2vh;
  }
}
