
@media screen and (max-width: 930px) {
  .card-number {
    padding: 0.3em;
    
  }
}

.card-body {
  border-style: solid;
  border-width: thick;
}

.cards {
  display: flex;
  align-items: stretch;
}

.card {
  width: 100%;
}

.card-number {
  text-align: center;
  font-size: 40px;
  flex-direction: row;
}

.card-message {
  float: left;
}

.main-card {
  border: 2px solid black;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 15vw;
  z-index: 999;

}

 @media screen and (max-width: 930px) {
  .main-card {
    margin-bottom: 10vh;
    
  }
}

@media screen and (max-width: 930px) {
  .main-card {
    width: 60%;
  }
} 