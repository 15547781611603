.list {
  display: flex;
  flex-direction: space-between;

  padding-top: 100px;
  margin-right: auto;
  margin-left: auto;
  width: 35%;
}

.column-name {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 90%;
}

.column-company {
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
}



.textHead {
  font-family: sans-serif; /* Change your font family */
  position: absolute;
  left: 850px;
  top: 200px;
}

.content-table {
  border-collapse: collapse;
  margin: 0px 0;
  font-size: 1.2em;
  min-width: 200px;
  border-radius: 5px 2px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  position: relative;
  position: absolute;
  overflow: auto;
}

.content-table thead tr {
  background-color: blue;
  color: white;
  text-align: center;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid blue;
}

.content-table tbody tr.danger-row {
  font-weight: bold;
  color: red;
}

.safe {
  color: green;
}

.notsafe {
  color: red;
}

td {
  text-align: center;
}


.list{
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;   
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  z-index: 99999;
}

.list2{
  background-color:white;
  height: 10rem;
  width: 80vw;  
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px gray, 0 2px 2px gray;
  padding: 0.5rem;
}

.header-list{
  text-align: center;
  
 
  
}


